import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header/Header';
import BingoCard from './components/BingoCard/BingoCard';
import Admin from './components/Admin/Admin';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <main>
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/" element={<BingoCard />} />
          </Routes>
        </main>

        <footer className="App-footer">
          <nav>
            <ul>
              <li>
                <Link to="/">BingoCard</Link>
              </li>
              <li>
                <Link to="/admin">Admin</Link>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    </Router>
  );
}

export default App;
