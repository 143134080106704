import { useState } from 'react';
import axios from 'axios';

export function useAuth() {
  const [auth, setAuth] = useState({ username: '', password: '' });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState('');

  const url = process.env.REACT_APP_API_URL;

  const handleLogin = (username, password) => {
    setAuth({ username, password });

    const headers = {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    };

    axios
      .get(`${url}/raiders/`, { headers })
      .then((res) => {
        setIsLoggedIn(true);
        setLoginError('');
      })
      .catch((err) => {
        console.error(err);
        setLoginError('Invalid credentials. Please try again.');
      });
  };

  return { auth, isLoggedIn, loginError, handleLogin };
}
