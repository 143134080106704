import React from 'react';

const SquaresList = ({ squaresHooks, raidersHooks }) => {
  return (
    <ul>
      {squaresHooks.squares
        .filter((square) => square.raiderId === raidersHooks.selectedRaiderId)
        .map((square) => (
          <li key={square.id} className="list-item">
            {!(squaresHooks.isEditingSquare && squaresHooks.editSquareId === square.id) && <span>{square.content}</span>}
            <div className="action-buttons">
              {squaresHooks.isEditingSquare && squaresHooks.editSquareId === square.id ? (
                <>
                  <input value={squaresHooks.editSquareContent} onChange={(e) => squaresHooks.setEditSquareContent(e.target.value)} />
                  <select value={squaresHooks.editSquareRaider} onChange={(e) => squaresHooks.setEditSquareRaider(e.target.value)}>
                    {raidersHooks.raiders.map((raider) => (
                      <option key={raider.id} value={raider.id}>
                        {raider.name}
                      </option>
                    ))}
                  </select>
                  <button onClick={squaresHooks.updateSquare}>Submit</button>
                  <button
                    onClick={() => {
                      squaresHooks.setIsEditingSquare(false);
                      squaresHooks.setEditSquareId(null);
                    }}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button onClick={() => squaresHooks.handleEditSquare(square.id, square.content, square.raiderId)}>Edit</button>
                  {!squaresHooks.isEditingSquare && <button onClick={() => squaresHooks.deleteSquare(square.id)}>Delete</button>}
                </>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
};

export default SquaresList;
