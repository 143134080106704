import React, { useState } from 'react';
import './Header.css';

function Header() {
  const [specialHeader, setSpecialHeader] = useState(false);

  if (Math.random() < 0.05) {
    setSpecialHeader(true);
  }

  return (
    <div className="header">
      <h1>{specialHeader ? 'Unknown Creed Raider Bingussy' : 'Unknown Creed Raider Bingo'}</h1>
    </div>
  );
}

export default Header;
