import React, { useState, useCallback, useEffect } from 'react';
import { useCardSquares } from '../../hooks/useCardSquares';
import './Popup.css';

const Popup = () => {
  const { popupSuppressed, setPopupSuppressed } = useCardSquares();
  const [isDragging, setIsDragging] = useState(false);

  const getInitialPosition = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const popupWidth = 304;
    const popupHeight = 208;

    const margin = {
      top: 155,
      bottom: 155,
      left: 200,
      right: 200,
    };

    const x = Math.random() * (width - popupWidth - margin.left - margin.right) + margin.left;
    const y = Math.random() * (height - popupHeight - margin.top - margin.bottom) + margin.top;

    return { x, y };
  };

  const [position, setPosition] = useState(getInitialPosition());
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleResize = () => {
      setPosition(getInitialPosition());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    window.open('https://www.youtube.com/watch?v=dQw4w9WgXcQ', '_blank');
  };

  const startDrag = (e) => {
    setIsDragging(true);
    setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
    e.preventDefault();
  };

  const onDrag = useCallback(
    (e) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - offset.x,
          y: e.clientY - offset.y,
        });
      }
    },
    [isDragging, offset],
  );

  const stopDrag = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', stopDrag);
    } else {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
    }

    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
    };
  }, [isDragging, onDrag]);

  const handleClose = () => {
    setPopupSuppressed(true);
  };

  if (popupSuppressed) {
    return null;
  }

  return (
    <div className="popup-container" style={{ left: `${position.x}px`, top: `${position.y}px` }} onMouseDown={startDrag}>
      <div className="popup-title-bar">
        Special Offer
        <button className="popup-close-button" onClick={handleClose}>
          X
        </button>
      </div>
      <div className="popup-inner">
        <h2 className="popup-h2">Congratulations!</h2>
        <p className="popup-p">You're the 1000th bingo winner!</p>
        <button className="popup-button" onClick={handleButtonClick}>
          Click here for your prize!
        </button>
      </div>
    </div>
  );
};

export default Popup;
