import React from 'react';
import { useCardSquares, useCardRaiders } from '../../hooks';
import Card from './Card/Card';
import RaiderSelection from './RaiderSelection/RaiderSelection';
import Popup from '../Popup/Popup';
import Fireworks from './Fireworks/Fireworks';
import './BingoCard.css';

function BingoCard() {
  const cardRaidersHooks = useCardRaiders();
  const cardSquaresHooks = useCardSquares(cardRaidersHooks.setErrorMessage, cardRaidersHooks.selectedRaiders, cardRaidersHooks.setSelectedRaiders);

  if (cardRaidersHooks.isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="bingo-card-container">
      {cardSquaresHooks.isBingo && <Popup />}
      {cardSquaresHooks.isBingo && <Fireworks />}
      {cardSquaresHooks.cardData ? (
        <Card cardSquaresHooks={cardSquaresHooks} />
      ) : (
        <RaiderSelection cardRaidersHooks={cardRaidersHooks} cardSquaresHooks={cardSquaresHooks} />
      )}
    </div>
  );
}

export default BingoCard;
