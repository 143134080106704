import React from 'react';

function Card({ cardSquaresHooks }) {
  return (
    <div className={`bingo-container ${cardSquaresHooks.isGrippers ? 'cascade-effect' : ''}`}>
      <div className="bingo-card">
        <div className="bingo-grid">
          {cardSquaresHooks.cardData.slice(0, 12).map((item) => {
            const isClicked = cardSquaresHooks.clickedSquares.includes(item.id);
            const isSwipingOut = cardSquaresHooks.swipingOutSquares.includes(item.id);
            const isSpecial = cardSquaresHooks.specialSquares.includes(item.id);

            return (
              <div
                key={item.id}
                className={`bingo-item ${isClicked ? 'clicked' : ''} ${isSwipingOut ? 'swipe-out' : ''} ${isSpecial ? 'special' : ''}`}
                onClick={() => cardSquaresHooks.handleSquareClick(item.id)}
              >
                <p>{item.content}</p>
                {item.Raider.name !== 'General' && <small>— {item.Raider.name}</small>}
              </div>
            );
          })}

          <div key="free-space" className="bingo-item free-space">
            Free Space
          </div>

          {cardSquaresHooks.cardData.slice(12).map((item) => {
            const isClicked = cardSquaresHooks.clickedSquares.includes(item.id);
            const isSwipingOut = cardSquaresHooks.swipingOutSquares.includes(item.id);
            const isSpecial = cardSquaresHooks.specialSquares.includes(item.id);

            return (
              <div
                key={item.id}
                className={`bingo-item ${isClicked ? 'clicked' : ''} ${isSwipingOut ? 'swipe-out' : ''} ${isSpecial ? 'special' : ''}`}
                onClick={() => cardSquaresHooks.handleSquareClick(item.id)}
              >
                <p>{item.content}</p>
                {item.Raider.name !== 'General' && <small>— {item.Raider.name}</small>}
              </div>
            );
          })}
        </div>
      </div>
      <button className="new-card-button" onClick={cardSquaresHooks.clearCardData}>
        Get New Card
      </button>
    </div>
  );
}

export default Card;
