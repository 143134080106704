import React, { useState } from 'react';

const Login = ({ handleLogin, loginError }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="login-form">
      <h1>Admin Panel Login</h1>
      <input placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
      <input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
      <button onClick={() => handleLogin(username, password)}>Login</button>
      {loginError && <div className="login-error">{loginError}</div>}
    </div>
  );
};

export default Login;
