import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

export function useRaiders(auth, isLoggedIn) {
  const [raiders, setRaiders] = useState([]);
  const [editRaiderId, setEditRaiderId] = useState(null);
  const [isEditingRaider, setIsEditingRaider] = useState(false);
  const [editRaiderName, setEditRaiderName] = useState('');
  const [newRaiderName, setNewRaiderName] = useState('');
  const [selectedRaiderId, setSelectedRaiderId] = useState('');

  const url = process.env.REACT_APP_API_URL;

  const headers = useMemo(
    () => ({
      Authorization: `Basic ${btoa(`${auth.username}:${auth.password}`)}`,
    }),
    [auth],
  );

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${url}/raiders/`, { headers })
        .then((res) => {
          setRaiders(res.data.raiders);
          if (res.data.raiders.length && !selectedRaiderId) {
            setSelectedRaiderId(res.data.raiders[0].id);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [isLoggedIn, auth, selectedRaiderId, headers, url]);

  const addRaider = () => {
    axios
      .post(`${url}/raiders/`, { name: newRaiderName }, { headers })
      .then((res) => {
        setRaiders((prevRaiders) => [...prevRaiders, res.data.raider]);
        setSelectedRaiderId(res.data.raider.id);
      })
      .catch((err) => console.error(err));
    setNewRaiderName('');
  };

  const updateRaider = () => {
    axios
      .patch(`${url}/raiders/${editRaiderId}`, { name: editRaiderName }, { headers })
      .then((response) => {
        const updatedRaiders = raiders.map((raider) => {
          if (raider.id === editRaiderId) {
            return { ...raider, name: editRaiderName };
          }
          return raider;
        });
        setRaiders(updatedRaiders);
        setIsEditingRaider(false);
        setEditRaiderId(null);
        setEditRaiderName('');
        setSelectedRaiderId(editRaiderId);
      })
      .catch((error) => {
        console.error('Error updating raider', error);
      });
  };

  const deleteRaider = (id) => {
    axios
      .delete(`${url}/raiders/${id}`, { headers })
      .then(() => {
        setRaiders(raiders.filter((raider) => raider.id !== id));
        setSelectedRaiderId(raiders[0].id);
      })
      .catch((err) => console.error(err));
  };

  const handleEditRaider = (raiderId, raiderName) => {
    setIsEditingRaider(true);
    setEditRaiderId(raiderId);
    setEditRaiderName(raiderName);
  };

  return {
    addRaider,
    deleteRaider,
    editRaiderId,
    editRaiderName,
    handleEditRaider,
    isEditingRaider,
    newRaiderName,
    raiders,
    selectedRaiderId,
    setEditRaiderId,
    setEditRaiderName,
    setIsEditingRaider,
    setNewRaiderName,
    setSelectedRaiderId,
    updateRaider,
  };
}
