import React from 'react';

const AddButtons = ({ raidersHooks, squaresHooks, selectedRaiderName }) => {
  return (
    <div className="add-buttons-container">
      <div className="add-raider">
        <input placeholder="New Raider Name" value={raidersHooks.newRaiderName} onChange={(e) => raidersHooks.setNewRaiderName(e.target.value)} />
        <button onClick={raidersHooks.addRaider}>Add Raider</button>
      </div>
      <div className="add-square">
        <input
          placeholder={selectedRaiderName ? `New Square for ${selectedRaiderName}` : 'Select a Raider'}
          value={squaresHooks.newSquareContent}
          onChange={(e) => squaresHooks.setNewSquareContent(e.target.value)}
        />
        <button onClick={squaresHooks.handleAddSquare}>{`Add Square for ${selectedRaiderName}`}</button>
      </div>
    </div>
  );
};

export default AddButtons;
