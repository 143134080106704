import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export function useCardSquares(setErrorMessage, selectedRaiders, setSelectedRaiders) {
  let initialCardData, initialClickedSquares, initialBingoTracker, initialSpecialSquares, initialIsBingo, initialPopupSuppressed;

  try {
    initialCardData = JSON.parse(localStorage.getItem('cardData')) || null;
    initialClickedSquares = JSON.parse(localStorage.getItem('clickedSquares')) || [];
    initialBingoTracker = JSON.parse(localStorage.getItem('bingoTracker')) || [];
    initialSpecialSquares = JSON.parse(localStorage.getItem('specialSquares')) || [];
    initialIsBingo = JSON.parse(localStorage.getItem('isBingo')) || false;
    initialPopupSuppressed = JSON.parse(localStorage.getItem('popupSuppressed')) || false;
  } catch (error) {
    console.error('Error getting local storage:', error);
    initialCardData = null;
    initialClickedSquares = [];
    initialBingoTracker = [];
    initialSpecialSquares = [];
    initialIsBingo = false;
    initialPopupSuppressed = false;
  }

  const [cardData, setCardData] = useState(initialCardData);
  const [clickedSquares, setClickedSquares] = useState(initialClickedSquares);
  const [swipingOutSquares, setSwipingOutSquares] = useState([]);
  const [specialSquares, setSpecialSquares] = useState(initialSpecialSquares);
  const [isGrippers, setIsGrippers] = useState(false);
  const [isBingo, setIsBingo] = useState(initialIsBingo);
  const [popupSuppressed, setPopupSuppressed] = useState(initialPopupSuppressed);
  const [bingoTracker, setBingoTracker] = useState(initialBingoTracker);

  const url = process.env.REACT_APP_API_URL;

  const generateCard = async () => {
    const apiEndpoint = `${url}/public/generate-card`;
    const raiderIds = selectedRaiders.map((raider) => raider.id);

    try {
      const response = await axios.post(apiEndpoint, { raiderIds });
      setCardData(response.data.card);
      setErrorMessage('');
    } catch (error) {
      console.error('Error generating card:', error.response.data.error);
      setErrorMessage(error.response.data.error);
    }
  };

  const clearCardData = useCallback(() => {
    localStorage.removeItem('cardData');
    localStorage.removeItem('clickedSquares');
    localStorage.removeItem('bingoTracker');
    localStorage.removeItem('specialSquares');
    localStorage.removeItem('isBingo');
    localStorage.removeItem('popupSuppressed');

    setCardData(null);
    setClickedSquares([]);
    setSelectedRaiders([]);
    setSpecialSquares([]);
    setIsGrippers(false);
    setIsBingo(false);
    setBingoTracker([]);
    setPopupSuppressed(false);
  }, [setSelectedRaiders]);

  useEffect(() => {
    try {
      localStorage.setItem('cardData', JSON.stringify(cardData));
      localStorage.setItem('clickedSquares', JSON.stringify(clickedSquares));
      localStorage.setItem('specialSquares', JSON.stringify(specialSquares));
      localStorage.setItem('specialSquares', JSON.stringify(specialSquares));
      localStorage.setItem('isBingo', isBingo);
      localStorage.setItem('bingoTracker', JSON.stringify(bingoTracker));
      localStorage.setItem('popupSuppressed', popupSuppressed);
      localStorage.setItem('isGrippers', isGrippers);
    } catch (error) {
      console.error('Error setting local storage:', error);
      clearCardData();
    }
  }, [cardData, clickedSquares, specialSquares, isBingo, bingoTracker, popupSuppressed, isGrippers, clearCardData]);

  const handleSquareClick = (id) => {
    const squareContent = cardData.find((item) => item.id === id).content.toLowerCase();
    const cardIndex = getIndexOfCardData(id);
    if (clickedSquares.includes(id)) {
      setSwipingOutSquares((prev) => [...prev, id]);
      setTimeout(() => {
        setSwipingOutSquares((prev) => prev.filter((item) => item !== id));
        setClickedSquares((prev) => prev.filter((item) => item !== id));
      }, 500);
      if (squareContent.includes('grippers')) {
        setIsGrippers(false);
      }
      setBingoTracker((prev) => prev.filter((item) => item !== cardIndex));
    } else if (specialSquares.includes(id)) {
      setSwipingOutSquares((prev) => [...prev, id]);
      setTimeout(() => {
        setSwipingOutSquares((prev) => prev.filter((item) => item !== id));
        setSpecialSquares((prev) => prev.filter((item) => item !== id));
      }, 500);
      if (squareContent.includes('grippers')) {
        setIsGrippers(false);
      }
      setBingoTracker((prev) => prev.filter((item) => item !== cardIndex));
    } else {
      if (squareContent.includes('grippers')) {
        setIsGrippers(true);
      }
      if (Math.random() < 0.01 || squareContent.includes('grippers')) {
        setSpecialSquares((prev) => [...prev, id]);
        setBingoTracker((prev) => [...prev, cardIndex]);
      } else {
        setClickedSquares((prev) => [...prev, id]);
        setBingoTracker((prev) => [...prev, cardIndex]);
      }
    }
  };

  const getIndexOfCardData = (id) => {
    return cardData.findIndex((item) => item.id === id);
  };

  const checkBingo = useCallback(() => {
    const validBingoArray = [
      [0, 1, 2, 3, 4],
      [5, 6, 7, 8, 9],
      [10, 11, 12, 13],
      [14, 15, 16, 17, 18],
      [19, 20, 21, 22, 23],
      [0, 5, 10, 14, 19],
      [1, 6, 11, 15, 20],
      [2, 7, 16, 21],
      [3, 8, 12, 17, 22],
      [4, 9, 13, 18, 23],
      [0, 6, 17, 23],
      [4, 8, 15, 19],
    ];

    let orderedBingoTracker = bingoTracker.sort((a, b) => a - b);
    for (let i = 0; i < validBingoArray.length; i++) {
      const bingoSet = validBingoArray[i];
      const isBingo = bingoSet.every((item) => orderedBingoTracker.includes(item));
      if (isBingo) {
        return true;
      }
    }
    return false;
  }, [bingoTracker]);

  useEffect(() => {
    if (checkBingo()) {
      setIsBingo(true);
    } else {
      setIsBingo(false);
      setPopupSuppressed(false);
    }
  }, [clickedSquares, specialSquares, popupSuppressed, checkBingo]);

  return {
    cardData,
    clearCardData,
    clickedSquares,
    generateCard,
    handleSquareClick,
    selectedRaiders,
    setCardData,
    setClickedSquares,
    setSelectedRaiders,
    specialSquares,
    swipingOutSquares,
    isGrippers,
    isBingo,
    popupSuppressed,
    setPopupSuppressed,
  };
}
