import React from 'react';

const RaiderName = ({ raidersHooks, selectedRaiderName }) => {
  return (
    <div className="raider-name-container">
      <h2 className="raider-header">{selectedRaiderName}'s Squares</h2>
      <div className="raider-header-buttons">
        {raidersHooks.isEditingRaider && raidersHooks.editRaiderId === raidersHooks.selectedRaiderId ? (
          <>
            <input value={raidersHooks.editRaiderName} onChange={(e) => raidersHooks.setEditRaiderName(e.target.value)} />
            <button onClick={raidersHooks.updateRaider}>Submit</button>
            <button
              onClick={() => {
                raidersHooks.setIsEditingRaider(false);
                raidersHooks.setEditRaiderId(null);
              }}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button onClick={() => raidersHooks.handleEditRaider(raidersHooks.selectedRaiderId, selectedRaiderName)}>Edit Raider</button>
            <button onClick={() => raidersHooks.deleteRaider(raidersHooks.selectedRaiderId)}>Delete Raider</button>
          </>
        )}
      </div>
    </div>
  );
};

export default RaiderName;
