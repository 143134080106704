import React from 'react';

const RaiderButtons = ({ raidersHooks }) => {
  return (
    <div className="raider-buttons">
      {raidersHooks.raiders.map((raider) => (
        <button
          key={raider.id}
          className={raidersHooks.selectedRaiderId === raider.id ? 'selected-raider' : ''}
          onClick={() => raidersHooks.setSelectedRaiderId(raider.id)}
        >
          {raider.name}
        </button>
      ))}
    </div>
  );
};

export default RaiderButtons;
