import React, { useEffect } from 'react';
import Login from './Login/Login';
import AddButtons from './AddButtons/AddButtons';
import RaiderName from './RaiderName/RaiderName';
import RaiderButtons from './RaiderButtons/RaiderButtons';
import SquaresList from './SquaresList/SquaresList';
import { useAuth, useRaiders, useSquares } from '../../hooks';
import './Admin.css';

function Admin() {
  const authHooks = useAuth();
  const raidersHooks = useRaiders(authHooks.auth, authHooks.isLoggedIn);
  const squaresHooks = useSquares(authHooks.auth, authHooks.isLoggedIn, raidersHooks.selectedRaiderId, raidersHooks.raiders);

  const selectedRaiderName = raidersHooks.raiders.find((r) => r.id === raidersHooks.selectedRaiderId)?.name || '';

  useEffect(() => {
    if (raidersHooks.raiders.length && !raidersHooks.selectedRaiderId) {
      raidersHooks.setSelectedRaiderId(raidersHooks.raiders[0].id);
    }
  }, [raidersHooks.raiders, raidersHooks.selectedRaiderId, raidersHooks]);

  return (
    <div className="admin-panel">
      {authHooks.isLoggedIn ? (
        <div className="admin-section">
          <RaiderButtons raidersHooks={raidersHooks} />
          <AddButtons raidersHooks={raidersHooks} squaresHooks={squaresHooks} selectedRaiderName={selectedRaiderName} />
          <RaiderName raidersHooks={raidersHooks} selectedRaiderName={selectedRaiderName} />
          <SquaresList squaresHooks={squaresHooks} raidersHooks={raidersHooks} />
        </div>
      ) : (
        <Login auth={authHooks.auth} handleLogin={authHooks.handleLogin} loginError={authHooks.loginError} />
      )}
    </div>
  );
}

export default Admin;
