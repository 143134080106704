import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

export function useSquares(auth, isLoggedIn, selectedRaiderId, raiders) {
  const [squares, setSquares] = useState([]);
  const [editSquareId, setEditSquareId] = useState(null);
  const [isEditingSquare, setIsEditingSquare] = useState(false);
  const [newSquareContent, setNewSquareContent] = useState('');
  const [editSquareContent, setEditSquareContent] = useState('');
  const [editSquareRaider, setEditSquareRaider] = useState('');

  const url = process.env.REACT_APP_API_URL;

  const headers = useMemo(
    () => ({
      Authorization: `Basic ${btoa(`${auth.username}:${auth.password}`)}`,
    }),
    [auth],
  );

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${url}/squares/`, { headers })
        .then((res) => setSquares(res.data.squares))
        .catch((err) => console.error(err));
    }
  }, [isLoggedIn, auth, headers, url]);

  const handleAddSquare = () => {
    axios
      .post(`${url}/squares/`, { content: newSquareContent, raiderId: selectedRaiderId }, { headers })
      .then((res) => {
        setSquares((prevSquares) => [...prevSquares, res.data.square]);
        setNewSquareContent('');
      })
      .catch((err) => console.error(err));
  };

  const updateSquare = () => {
    const intEditSquareRaider = parseInt(editSquareRaider, 10);

    axios
      .patch(`${url}/squares/${editSquareId}`, { content: editSquareContent, raiderId: intEditSquareRaider }, { headers })
      .then((response) => {
        const updatedSquares = squares.map((square) => {
          if (square.id === editSquareId) {
            return {
              ...square,
              content: editSquareContent,
              raiderId: intEditSquareRaider,
            };
          }
          return square;
        });
        setSquares(updatedSquares);
        setIsEditingSquare(false);
        setEditSquareId(null);
        setEditSquareContent('');
        setEditSquareRaider('');
      })
      .catch((error) => {
        console.error('Error updating square', error);
      });
  };

  const handleEditSquare = (squareId, squareContent, squareRaider) => {
    setIsEditingSquare(true);
    setEditSquareId(squareId);
    setEditSquareContent(squareContent);
    setEditSquareRaider(squareRaider);
  };

  const deleteSquare = (id) => {
    axios
      .delete(`${url}/squares/${id}`, { headers })
      .then(() => setSquares(squares.filter((square) => square.id !== id)))
      .catch((err) => console.error(err));
  };

  return {
    deleteSquare,
    editSquareContent,
    editSquareId,
    editSquareRaider,
    handleAddSquare,
    handleEditSquare,
    isEditingSquare,
    newSquareContent,
    setEditSquareContent,
    setEditSquareId,
    setEditSquareRaider,
    setIsEditingSquare,
    setNewSquareContent,
    squares,
    updateSquare,
  };
}
