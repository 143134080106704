import React from 'react';

function RaiderSelection({ cardRaidersHooks, cardSquaresHooks }) {
  return (
    <div className="generate-card-wrapper">
      <div className="instruction-text"> Select the raiders you want to include on your card. </div>
      <div className="raider-selection">
        {cardRaidersHooks.raiders.map((raider) => (
          <button
            key={raider.id}
            className={`raider-button ${cardSquaresHooks.selectedRaiders.includes(raider) ? 'selected' : ''}`}
            onClick={() => cardRaidersHooks.toggleRaiderSelection(raider)}
          >
            {raider.name}
          </button>
        ))}
        <button className="generate-card-button" onClick={cardSquaresHooks.generateCard}>
          Generate Card
        </button>
        {cardRaidersHooks.errorMessage && <div className="error-tooltip">{cardRaidersHooks.errorMessage}</div>}
        <button className="select-all-button" onClick={cardRaidersHooks.selectAllRaiders}>
          Select All
        </button>
      </div>
    </div>
  );
}

export default RaiderSelection;
