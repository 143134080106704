import { useState, useEffect } from 'react';
import axios from 'axios';

export function useCardRaiders() {
  const initialSelectedRaiders = JSON.parse(localStorage.getItem('selectedRaiders')) || [];

  const [selectedRaiders, setSelectedRaiders] = useState(initialSelectedRaiders);
  const [raiders, setRaiders] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchRaiders() {
      try {
        const response = await axios.get(`${url}/public/raiders-list`);
        setRaiders(response.data.raiders);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching raiders list:', error);
      }
    }

    fetchRaiders();
  }, [url]);

  const toggleRaiderSelection = (raider) => {
    setSelectedRaiders((prev) => (prev.includes(raider) ? prev.filter((r) => r !== raider) : [...prev, raider]));
  };

  const selectAllRaiders = () => {
    setSelectedRaiders(raiders);
  };

  return {
    errorMessage,
    isLoading,
    raiders,
    selectAllRaiders,
    selectedRaiders,
    setErrorMessage,
    setSelectedRaiders,
    toggleRaiderSelection,
  };
}
